var Cameraland = Cameraland || {};

Cameraland.Insurance = class {
    constructor(options) {
        this.hookEvents = this.hookEvents.bind(this);
        this.handleEvent = this.handleEvent.bind(this);
        this.addInsurance = this.addInsurance.bind(this);
        this.removeInsurance = this.removeInsurance.bind(this);
        this.loadingMask = this.loadingMask.bind(this);


        this.options = jQuery.extend({}, {
            insuranceForm: '.insurance_form',
            loadingMaskSelector: '.insurance-loader',
            miniCartSelector: 'div.header-minicart',
            totalsSelector: 'div.cart-totals-inner'
        }, options);

        this.hookEvents();
    }

    hookEvents() {
        jQuery(this.options.insuranceForm).on('change', 'input', (event) => {
            this.handleEvent(event);
        });
    }

    handleEvent(event) {
        const insurance = event.currentTarget.value;
        const value = jQuery(event.currentTarget).attr('data-value');
        const parts = value.split('_');

        this.loadingMask(true, jQuery(event.currentTarget));

        if (insurance == 1) {
            this.addInsurance(parts, jQuery(event.currentTarget));
        } else {
            this.removeInsurance(parts, jQuery(event.currentTarget));
        }
    }

    addInsurance(parts, currentElement) {
        const miniCart = jQuery(this.options.miniCartSelector);
        const totals = jQuery(this.options.totalsSelector);

        new Ajax.Request(this.options.addURL, {
            method: 'get',
            parameters: {parent_item_id: parts[0], product: parts[1]},
            onSuccess: (data) => {
                const result = data.responseText.evalJSON(true);
                miniCart.html(result['minicart']);
                totals.html(result['totals']);
                this.loadingMask(false, currentElement);
            },
            onFailure: () => {
                console.log('Insurance not added!');
                this.loadingMask(false, currentElement);
            }
        });
    }

    removeInsurance(parts, currentElement) {
        const miniCart = jQuery(this.options.miniCartSelector);
        const totals = jQuery(this.options.totalsSelector);

        new Ajax.Request(this.options.removeURL, {
            method: 'get',
            parameters: {item_to_insure_id: parts[0], product_id: parts[1]},
            onSuccess: (data) => {
                const result = data.responseText.evalJSON(true);
                miniCart.html(result['minicart']);
                totals.html(result['totals']);
                this.loadingMask(false, currentElement);
            },
            onFailure: () => {
                console.log('Insurance not removed!');
                this.loadingMask(false, currentElement);
            }
        });
    }

    loadingMask(show, currentElement) {
        const loadingMask = currentElement.closest('.product-cart-item-bottom').find(this.options.loadingMaskSelector);
        if (show) {
            loadingMask.show();
        } else {
            loadingMask.hide();
        }
    }
};